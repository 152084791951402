
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "Error",
})
export default class Error extends Vue {
  visitRoute(link: string): void {
    this.$router.push(link);
  }
}
